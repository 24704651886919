import React, { useEffect } from "react";
import CallToAction from "./cta";

const PricingTables = ({ ref }) => {
  return (
    <div
      id="pricing"
      className="text-center bg-yellow-950 pt-16 pb-16 md:pb-32 w-full  md:w-100"
    >
      <h2 className="text-3xl font-bold text-white mb-16 uppercase">
        Our Products
      </h2>
      <div className="wrapper block md:grid w-screen place-content-center font-semibold md;px-8">
        <div className="pricing-table w-full rounded-md text-slate-900 md:justify-between flex md:flex-row flex-col justify-center md:px-4">
          {/* herbal coffee */}
          <div class="relative m-10 flex w-full max-w-xs flex-col overflow-hidden rounded-lg border border-gray-100 bg-white shadow-md">
            <a
              class="relative mx-3 mt-3 flex h-60 overflow-hidden rounded-xl"
              href="#"
            >
              <img
                className="object-fill w-full"
                src="/PXL_20240615_110216765.NIGHT-01.jpeg"
                alt="product image"
              />
              <span class="absolute top-0 left-0 m-2 rounded-full bg-black px-2 text-center text-sm font-medium text-white">
                Often Bought{" "}
              </span>
            </a>
            <div class="mt-4 px-5 pb-5">
              <a href="#">
                <h5 class="text-xl tracking-tight text-slate-900">
                  Herbal Coffee Powder{" "}
                </h5>
              </a>
              <div class="mt-2 mb-5 flex text-center items-center justify-between">
                <p className="text-center w-full">
                  <span class="text-lg text-center  font-bold text-slate-900">
                    LKR400
                  </span>
                </p>
              </div>
              <CallToAction product={" Herbal Coffee Powder "} />
            </div>
          </div>
          {/* roasted coffee */}{" "}
          <div class="relative m-10 flex w-full max-w-xs flex-col overflow-hidden rounded-lg border border-gray-100 bg-white shadow-md">
            <a
              class="relative mx-3 mt-3 flex h-60 overflow-hidden rounded-xl"
              href="#"
            >
              <img
                className="object-cover w-full"
                src="/2024062814294740.jpg"
                alt="product image"
              />
            </a>
            <div class="mt-4 px-5 pb-5">
              <a href="#">
                <h5 class="text-xl tracking-tight text-slate-900">
                  Roasted Coffee Powder{" "}
                </h5>
              </a>
              <div class="mt-2 mb-5  items-center justify-between">
                <p className="w-full text-center">
                  <span class="text-lg font-bold text-slate-900">LKR350</span>
                </p>
              </div>
              <CallToAction product={"Roasted Coffee Powder"} />
            </div>
          </div>
          {/* roasted beans */}
          <div class="relative m-10 flex w-full max-w-xs flex-col overflow-hidden rounded-lg border border-gray-100 bg-white shadow-md">
            <a
              class="relative mx-3 mt-3 flex h-60 overflow-hidden rounded-xl"
              href="#"
            >
              <img
                className="object-cover w-full"
                src="/PXL_20240615_111112174.NIGHT.jpg"
                alt="product image"
              />
            </a>
            <div class="mt-4 px-5 pb-5">
              <a href="#">
                <h5 class="text-xl tracking-tight text-slate-900">
                  Roasted Beans{" "}
                </h5>
              </a>
              <div class="mt-2 mb-5  items-center justify-between">
                <p className="w-full text-center">
                  <span class="text-sm font-bold text-slate-900">
                    LKR2600 per kg (Negotiable)
                  </span>
                </p>
                <p className="text-sm text-red-600">Minimum order 500g</p>
              </div>
              <CallToAction product={"Roasted Beans"} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default PricingTables;
