import "./App.css";
import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { DBProvider } from "./context/database_context";
import Landing from "./pages/landing";
import { ToastProvider, useToasts } from "react-toast-notifications";

import { ThemeProvider } from "./context/theme_context";
function App() {
  return (
    <ThemeProvider>
      <DBProvider>
        <ToastProvider>
          <div className="App scroll-smooth	bg-yellow-950">
            <Router>
              <Routes>
                {/* keep this for now */}
                <Route path={"/landing"} element={<Landing />} />
                {/* landing will become main page when we change dns */}
                <Route path={"/"} element={<Landing />} />
              </Routes>
            </Router>
          </div>
        </ToastProvider>
      </DBProvider>
    </ThemeProvider>
  );
}

export default App;
