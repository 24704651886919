import React, { useState, useEffect } from "react";
import CallToAction from "../cta";
import { getLeaveBehindData } from "../utility";

const LeaveBehind = () => {
  const [leaveBehindContent, setLeaveBehindContent] = useState({});

  useEffect(() => {
    const data = getLeaveBehindData();
    setLeaveBehindContent(data);
  }, []);

  return (
    <div className="mx-auto md:w-10/12 flex flex-col md:flex-row items-center justify-center py-8">
      {/* Left Column */}
      <div className="md:w-1/2 w-11/12 flex items-center justify-center md:pr-8">
        {leaveBehindContent.Image && (
          <img
            className="max-w-full max-h-full"
            src="/belowbanner-img.JPG"
            alt="Responsive Image"
          />
        )}
      </div>

      {/* Right Column */}
      <div className="md:w-1/2 w-11/12 p-4">
        <h2 className="text-3xl font-bold text-yellow-700 text-left mb-8 md:mt-0 mt-8">
          {leaveBehindContent.title}
        </h2>
        {leaveBehindContent?.content?.map((c, i) => {
          return (
            <div className="mb-8">
              <div className="text-left font-bold">
                {i + 1}. {c?.title}
              </div>
              <p className=" text-left  text-slate-700">{c?.content}</p>
            </div>
          );
        })}

        <div className="flex space-y-4 justify-center">
          {leaveBehindContent.buttons &&
            leaveBehindContent.buttons.map((button, index) => (
              <CallToAction key={index} link={button.link} text={button.text} />
            ))}
        </div>
      </div>
    </div>
  );
};

export default LeaveBehind;
