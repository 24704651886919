import React, { useState, useEffect } from "react";
import CallToAction from "../cta";
import { getHeroData } from "../utility";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
const Hero = () => {
  const [heroContent, setHeroContent] = useState({});
  const [imageLoaded, setImageLoaded] = useState(false);

  useEffect(() => {
    const data = getHeroData();
    setHeroContent(data);
  }, []);

  const handleImageLoad = () => {
    setImageLoaded(true);
  };

  return (
    <LazyLoadComponent>
      <div
        id="top"
        className={`h-screen flex flex-col items-center justify-center ${
          imageLoaded ? "bg-yellow-900" : ""
        }`}
        style={{
          backgroundImage: imageLoaded
            ? `url(/PXL_20240615_111112174.NIGHT.jpg)`
            : "none",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {
          !imageLoaded && <Skeleton count={5} /> // Five-line loading skeleton
        }
        <img
          src="/PXL_20240615_111112174.NIGHT.jpg"
          alt="background"
          className="hidden" // hide the actual img element
          onLoad={handleImageLoad}
        />
        {imageLoaded && (
          <div className="text-center bg-black bg-opacity-60 py-4 text-white mb-8 w-11/12">
            <p className="text-xl md:text-xl lg:text-2xl font-semibold mb-8">
              {heroContent.subtitle}
            </p>
            <h1 className="text-4xl md:text-6xl lg:text-7xl font-bold">
              {heroContent.title}
            </h1>
          </div>
        )}
        {imageLoaded && (
          <div className="flex flex-col space-y-4">
            {heroContent.buttons &&
              heroContent.buttons.map((button, index) => (
                <CallToAction
                  key={index}
                  link={button.link}
                  text={button.text}
                  className="bg-white hover:bg-yellow-50 text-yellow-900 font-bold py-4 px-8 rounded uppercase text-lg transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-300 hover:shadow-2xl"
                />
              ))}
          </div>
        )}
      </div>
    </LazyLoadComponent>
  );
};

export default Hero;
