export const faqdata = [
  {
    question: "What is the process for it to make finely ground coffee?",
    description:
      "At COFIDU, our finely ground coffee is crafted through a meticulous process to ensure exceptional quality and consistency. Here's a brief overview of the process:",
    answer: [
      {
        title: "Selection of Premium Beans:",
        content:
          "We start by carefully selecting high-quality coffee beans from renowned coffee-growing regions around Sri Lanka. These beans are chosen for their distinct flavor profiles and characteristics.",
      },
      {
        title: "Precision Grinding:",
        content:
          "The selected beans undergo a precision grinding process to achieve the desired fineness. Our state-of-the-art grinding equipment ensures uniform particle size, which is crucial for extracting optimal flavor during brewing.",
      },
      {
        title: "Quality Control:",
        content:
          "Throughout the grinding process, stringent quality control measures are in place to maintain consistency and freshness. We conduct regular inspections to monitor factors such as particle size, aroma, and flavor profile.",
      },
      {
        title: "Packaging:",
        content:
          "Once the coffee is finely ground to perfection, it is promptly sealed in airtight packaging to preserve its freshness and flavor. Our packaging is designed to protect the coffee from exposure to light, moisture, and oxygen, ensuring that every cup is as flavorful as the first.",
      },
      {
        title: "Testing and Tasting:",
        content:
          "Before reaching our customers, each batch of finely ground coffee undergoes rigorous testing and tasting by our expert team. We are committed to delivering a consistently exceptional product that meets the highest standards of quality and taste.",
      },
    ],
    end: "By following this meticulous process, COFIDU produces finely ground coffee that promises an unparalleled sensory experience with every sip.",
  },
  {
    question:
      "What are the quality control measures in place during the grinding process to ensure Safety and Hygiene?",
    description:
      "At COFIDU, we prioritize the safety and hygiene of our products throughout every stage of production, including the grinding process. Here are the quality control measures we implement:",
    answer: [
      {
        title: "Sanitation Protocols:",
        content:
          "Our production facilities adhere to strict sanitation protocols in accordance with industry standards and regulations. All equipment, surfaces, and utensils involved in the grinding process are regularly cleaned and sanitized to prevent contamination.",
      },
      {
        title: "Employee Training:",
        content:
          "Our staff members undergo comprehensive training on hygiene practices and food safety protocols. They are educated on proper handwashing techniques, personal protective equipment (PPE) usage, and the importance of maintaining a clean working environment.",
      },
      {
        title: "HACCP Principles:",
        content:
          "We follow Hazard Analysis and Critical Control Points (HACCP) principles to identify and control potential hazards in the grinding process. This systematic approach helps us prevent food safety hazards, such as microbial contamination, throughout the production cycle.",
      },
      {
        title: "Quality Assurance Checks:",
        content:
          "Throughout the grinding process, our quality assurance team conducts regular checks to monitor product quality and safety. This includes visual inspections, sensory evaluations, and microbial testing to ensure that our products meet stringent safety standards.",
      },
      {
        title: "Traceability Systems:",
        content:
          "We maintain meticulous records and traceability systems to track the origin of raw materials used in the grinding process. This enables us to quickly trace any potential issues back to their source and implement corrective actions if necessary.",
      },
      {
        title: "Packaging Integrity:",
        content:
          "Prior to packaging, our products undergo thorough inspections to ensure packaging integrity and seal effectiveness. This safeguards the product from external contaminants and helps maintain its freshness and quality.",
      },
    ],
    end: "By implementing these rigorous quality control measures, COFIDU ensures that our finely ground coffee products meet the highest standards of safety, hygiene, and quality for our valued customers.",
  },
  {
    question:
      "What Packaging materials and methods do we use to preserve the quality and freshness of our product?",
    description:
      "At COFIDU, we are committed to delivering coffee products of the highest quality and freshness. To achieve this, we employ advanced packaging materials and methods designed to protect the flavor and aroma of our finely ground coffee.",
  },
  {
    question:
      "How do we create unique flavor profiles and blends that appeal to different tastes with COFIDU?",
    description:
      "At COFIDU, we take pride in our ability to craft exceptional flavor profiles and blends that cater to a wide range of tastes and preferences. Here's how we achieve this:",
    answer: [
      {
        title: "Selection of Premium Beans:",
        content:
          "We start by carefully selecting premium coffee beans from renowned coffee-growing regions around the world. Each variety of bean brings its own unique flavor characteristics, influenced by factors such as altitude, soil composition, and climate.",
      },
      {
        title: "Expert Roasting Process:",
        content:
          "Our master roasters skillfully roast the coffee beans to perfection, taking into account the desired flavor profile for each blend. By carefully controlling factors such as roast duration and temperature, we are able to enhance the inherent flavors of the beans and achieve a balanced and nuanced taste.",
      },
      {
        title: "Blend Development:",
        content:
          "Our team of flavor experts meticulously experiment with different combinations of coffee beans to create distinctive blends that offer complexity and depth of flavor. We consider factors such as bean origin, roast level, and flavor notes to develop blends that appeal to a variety of palates.",
      },
      {
        title: "Flavor Infusions and Additions:",
        content:
          "In addition to traditional coffee blends, we also explore innovative flavor infusions and additions to create unique and exciting taste experiences. Whether it's infusing coffee with exotic spices, incorporating natural flavor extracts, or experimenting with new brewing techniques, we continuously push the boundaries to delight our customers with unexpected flavors.",
      },
      {
        title: "Customer Feedback and Collaboration:",
        content:
          "We value feedback from our customers and actively engage with them to understand their preferences and tastes. This input informs our product development process, allowing us to tailor our offerings to meet the evolving needs of our diverse customer base. Additionally, we collaborate with industry experts, baristas, and coffee enthusiasts to exchange ideas and inspiration for creating new and innovative flavor profiles.",
      },
      {
        title: "Commitment to Quality and Consistency:",
        content:
          "Throughout the flavor development and blending process, we maintain a steadfast commitment to quality and consistency. Every batch of coffee undergoes rigorous testing and tasting by our expert team to ensure that it meets our high standards of excellence.",
      },
    ],
    end: "By leveraging our expertise, creativity, and dedication to quality, COFIDU is able to create unique flavor profiles and blends that captivate the senses and delight coffee lovers around the world.",
  },
  {
    question:
      "What are the varieties of coffee which can be generated through COFIDU?",
    description:
      "COFIDU is dedicated to providing a diverse range of coffee options to suit different preferences and lifestyles. While our offerings may vary depending on availability and customer demand, here are some of the varieties of coffee that COFIDU currently offers:",
    answer: [
      {
        title: "Roasted Coffee:",
        content:
          "Our classic roasted coffee varieties showcase the rich flavors and aromas that coffee enthusiasts love. From medium roasts with balanced acidity to dark roasts with bold intensity, we offer a spectrum of roasting profiles to cater to diverse tastes.",
      },
      {
        title: "Herbal Coffee:",
        content:
          "In addition to traditional coffee blends, COFIDU also offers herbal coffee options for those seeking unique flavor experiences. Our herbal coffee blends combine premium coffee beans with natural herbs and spices to create distinctive and aromatic brews that offer a refreshing alternative to traditional coffee.",
      },
    ],
    end: "Please note that our selection of coffee varieties may evolve over time based on factors such as seasonal availability, customer feedback, and market trends. We are committed to continually expanding our range of offerings to provide coffee lovers with new and exciting options to explore.",
  },
  {
    question:
      "How is ground coffee generated during the coffee processing and grinding process?",
    description:
      "The process of generating ground coffee begins with the careful selection of premium coffee beans, followed by several key steps:",
    answer: [
      {
        title: "Harvesting and Processing:",
        content:
          "Coffee beans are harvested from coffee plants once they reach optimal ripeness. The harvested cherries undergo processing to remove the outer layers and extract the coffee beans inside. This can be done using either the dry or wet method, depending on the desired flavor profile.",
      },
      {
        title: "Drying and Sorting:",
        content:
          "After processing, the coffee beans are dried to reduce moisture content and stabilize their flavor. Once dried, the beans are sorted based on size, density, and quality to ensure consistency in the final product.",
      },
      {
        title: "Roasting:",
        content:
          "The sorted coffee beans are then roasted to develop their characteristic flavor and aroma. During the roasting process, the beans undergo chemical changes that result in the formation of aromatic compounds and the release of oils. The degree of roasting can vary, ranging from light to dark, depending on the desired flavor profile.",
      },
      {
        title: "Grinding:",
        content:
          "Once the beans have been roasted to perfection, they are ground to the desired consistency. The grinding process involves using precision equipment to break down the roasted coffee beans into smaller particles. The grind size can vary from coarse to fine, depending on the intended brewing method.",
      },
    ],
    end: "After grinding, the ground coffee is promptly packaged to preserve its freshness and flavor. At COFIDU, we take great care to ensure that each step of the process is executed with precision and attention to detail, resulting in high-quality ground coffee that delivers a delightful sensory experience.",
  },
  {
    question:
      "How do we ensure that our coffee meets the highest standards of quality and flavor?",
    description:
      "At COFIDU, our commitment to quality and flavor is at the heart of everything we do. We employ a comprehensive approach to ensure that our coffee consistently meets the highest standards:",
    answer: [
      {
        title: "Sourcing Premium Beans:",
        content:
          "We start by sourcing premium coffee beans from trusted suppliers and coffee-growing regions known for their exceptional quality. Our rigorous selection process ensures that we use only the finest beans with desirable flavor profiles.",
      },
      {
        title: "Expert Roasting Techniques:",
        content:
          "Our master roasters bring years of expertise to the table, carefully roasting each batch of coffee to unlock its full potential. By controlling variables such as temperature, time, and roast level, we achieve a harmonious balance of flavors that highlights the unique characteristics of each bean.",
      },
      {
        title: "Quality Control Measures:",
        content:
          "Throughout the production process, we implement stringent quality control measures to monitor and maintain consistency. This includes regular sensory evaluations, cupping sessions, and laboratory testing to ensure that every batch of coffee meets our exacting standards.",
      },
      {
        title: "Innovation and Continuous Improvement:",
        content:
          "We continuously explore new methods, techniques, and technologies to enhance the quality and flavor of our coffee. Our team stays abreast of industry trends and collaborates with experts to incorporate innovative practices into our production process.",
      },
      {
        title: "Customer Feedback:",
        content:
          "We value feedback from our customers and use it as a valuable source of insight to refine our products. Listening to our customers' preferences and experiences helps us make informed decisions and continually improve our offerings.",
      },
    ],
    end: "By combining our dedication to quality, expertise in roasting, and commitment to innovation, COFIDU ensures that every cup of our coffee delivers a rich, flavorful, and satisfying experience for coffee lovers around the world.",
  },
  {
    question:
      "How do we address any inquiries or concerns raised by customers regarding ground coffee and its impact on product safety and quality?",
    answer: [
      {
        title: "Prompt and Personalized Response:",
        content:
          "We respond to customer inquiries or concerns promptly and personally. Our customer support team is dedicated to addressing your questions and providing assistance tailored to your specific needs.",
      },
      {
        title: "Transparent Information:",
        content:
          "We believe in transparency and providing accurate information about our ground coffee products. If you have any questions about our sourcing, production process, or quality control measures, we are happy to provide detailed information to address your concerns.",
      },
      {
        title: " Quality Assurance:",
        content:
          " COFIDU prioritizes product safety and quality at every stage of production. Our ground coffee products undergo rigorous quality assurance checks to ensure they meet our high standards of excellence. We adhere to strict hygiene and sanitation protocols to maintain the integrity of our products.",
      },
      {
        title: "Customer Satisfaction Guarantee:",
        content:
          "Your satisfaction is our top priority. If you have any issues with our ground coffee products, we will work with you to find a satisfactory resolution. Whether it's a refund, replacement product, or other resolution options, we are committed to ensuring your satisfaction.",
      },
      {
        title: "Continuous Improvement:",
        content:
          "We value customer feedback as an opportunity for continuous improvement. Your input helps us identify areas for enhancement and innovation, allowing us to continually improve our products and services to better meet your needs.",
      },
    ],
    description:
      "At COFIDU, we are committed to providing our customers with the highest quality coffee products and ensuring their satisfaction. If you have any inquiries or concerns regarding our ground coffee and its impact on product safety and quality, we are here to help. Here's how we address customer inquiries and concerns:",
  },
];
