import React, { useEffect, useState, useRef } from "react";
import Footer from "../components/footer";
import Hero from "../components/landing_pages/hero";
import Ribbon from "../components/landing_pages/ribbon";
import LeaveBehind from "../components/landing_pages/leavebehind";
import ServiceAreas from "../components/landing_pages/serviceareas";
import HowItWorks from "../components/landing_pages/howitworks";
import TimeNot from "../components/landing_pages/timenot";
import Marquee from "../components/landing_pages/marquee";
import ReviewsCarousel from "../components/landing_pages/reviews";
import About from "../components/landing_pages/about";
import FaqSection from "../components/landing_pages/faq";
import PricingTables from "../components/pricing_tables";

function Landing() {
  return (
    <div className="bg-white">
      <Hero />
      <Ribbon />
      <About />
      <PricingTables />
      <TimeNot />
      <Marquee />
      <LeaveBehind />
      {/* <ServiceAreas /> */}
      {/* <HowItWorks /> */}
      <ReviewsCarousel />
      <FaqSection />
      {/* <Footer /> */}
    </div>
  );
}

export default Landing;
