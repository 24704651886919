import React from "react";
import { Link } from "react-router-dom";
import ReactWhatsapp from "react-whatsapp";
const CallToAction = ({ link, text, className, product }) => {
  if (product) {
    return (
      <ReactWhatsapp
        number="+94788530806"
        message={`Good day! I would like to place an order for my favorite ${product}, infused with love.`}
        className={`${
          className
            ? className
            : "bg-yellow-950 cursor-pointer hover:bg-yellow-900 text-white font-bold py-4 px-8 rounded uppercase text-lg transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-300 hover:shadow-2xl"
        }`}
      >
        Order Now
      </ReactWhatsapp>
    );
  } else {
    return (
      <div
        onClick={(e) => {
          e.preventDefault();
          var element = document.getElementById("pricing");
          element.scrollIntoView();
          element.scrollIntoView(false);
          element.scrollIntoView({ block: "start" });
          element.scrollIntoView({
            behavior: "smooth",
            block: "start",
            inline: "nearest",
          });
        }}
        id="order_now"
        className={`${
          className
            ? className
            : "bg-yellow-950 cursor-pointer hover:bg-yellow-900 text-white font-bold py-4 px-8 rounded uppercase text-lg transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-105 duration-300 hover:shadow-2xl"
        }`}
      >
        Order Now
      </div>
    );
  }
};

export default CallToAction;
