import { gql } from "urql";

export const AddReviewMutation = gql`
  mutation InsertReview($comment: String = "", $name: String = "", $star: Int) {
    insert_reviews_one(
      object: { comment: $comment, name: $name, star: $star }
    ) {
      id
    }
  }
`;

export const GetReviewsQuery = gql`
  query GetReviews {
    reviews(order_by: { created_at: desc }) {
      comment
      id
      name
      star
      created_at
    }
  }
`;
