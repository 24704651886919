import React, { useState, useEffect } from "react";
import CallToAction from "../cta";
import { getFaqData } from "../utility";
import { faqdata } from "../../data/faq";
const FaqSection = () => {
  const [faqContent, setFaqContent] = useState([]);
  const [activeIndex, setActiveIndex] = useState(null);

  useEffect(() => {
    setFaqContent(faqdata);
  }, []);

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div id="faq" className="bg-yellow-950 py-16">
      <div className="max-w-4xl mx-auto w-full md:w-9/12">
        <h1 className="text-3xl font-bold text-white mb-8 uppercase">FAQ</h1>
        <div className="space-y-4 px-8">
          {faqContent?.length > 0 &&
            faqContent?.map((faq, index) => (
              <div key={index} className="border rounded-lg overflow-hidden">
                <button
                  onClick={() => toggleAccordion(index)}
                  className="w-full text-left p-4 bg-white focus:outline-none"
                >
                  <div className="flex items-center justify-between">
                    <span className="text-lg font-semibold">
                      {faq?.question}
                    </span>
                    <svg
                      className={`w-6 h-6 transition-transform transform ${
                        activeIndex === index ? "rotate-180" : ""
                      }`}
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </div>
                </button>
                {activeIndex === index && (
                  <div className="p-4 bg-yellow-50">
                    <div className="p-4 bg-yellow-50 font-semibold text-slate-700 text-left">
                      <p>{faq?.description}</p>
                    </div>
                    {faq?.answer ? (
                      <div>
                        <ul type="a">
                          {faq?.answer?.map((faqcont, id) => {
                            return (
                              <li key={id} className="text-left px-4">
                                <span className="font-semibold text-slate-700 ">
                                  {faqcont?.title}
                                </span>
                                <span className=" text-slate-700 text-left">
                                  {faqcont?.content}
                                </span>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    ) : null}
                    <div className="p-4 bg-yellow-50  text-slate-700 text-left">
                      <p>{faq?.end}</p>
                    </div>
                  </div>
                )}
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default FaqSection;
