import React from "react";
import { getMarqueeData } from "../utility";

const Marquee = () => {
  const marqueeData = getMarqueeData();

  return (
    <div
      className={`bg-yellow-950 text-white py-8 holder text-2xl md:flex ${marqueeData.backgroundColor} ${marqueeData.textColor} ${marqueeData.holderClasses}`}
    >
      <span className="font-semibold">{marqueeData.titlePart1}</span>
      {/* <div
        className={`marquee md:mx-5 mx-auto md:my-2 my-4 ${marqueeData.marqueeClasses}`}
      >
        <div className={`marquee--inner ${marqueeData.innerClasses}`}>
          <span>
            <div className="font-semibold">{marqueeData.text}</div>
          </span>
        </div>
      </div> */}
      <span className="font-semibold">{marqueeData.titlePart2}</span>
    </div>
  );
};

export default Marquee;
